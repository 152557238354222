//--------------------------------------------------------------
// FAQS
//--------------------------------------------------------------

.faqs {
	$parent: &;

	// <details>
	&__question {
		&[open] {
			#{$parent}__title {
				color: $color-primary;
			}
		}

		&:focus {
			#{$parent}__title {
				color: $color-primary;
			}
		}
	}

	// <summary>
	&__title {
		&::-webkit-details-marker {
			display: none;
		}

		&:focus,
		&:hover {
			color: $color-primary;
		}
	}

	&__content {
		padding: 10px 25px;
	}
}
