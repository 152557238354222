//--------------------------------------------------------------
// WYSIWYG CONTENT
//--------------------------------------------------------------

//-----------------------------------------
// Paragraph
//-----------------------------------------
.wysiwyg-content {
	p {
		margin: 0 0 $global-spacing 0;
	}
}

//-----------------------------------------
// List
//-----------------------------------------
.wysiwyg-content {
	ul,
	ol {
		margin: 0 0 $global-spacing 0;
	}

	// Unordered List
	ul {
		list-style-type: disc;
		padding: 0 ($global-padding * 2) 0 $global-padding;
	}

	// Ordered List
	ol {
		list-style-position: inside;
		list-style-type: decimal;
		padding: 0 ($global-padding * 2) 0 0;
	}
}

//-----------------------------------------
// List Item
//-----------------------------------------
.wysiwyg-content {
	li {
		&:not(:last-child) {
			margin-bottom: ($global-spacing / 2);
		}
	}
}

//-----------------------------------------
// Blockquote
//-----------------------------------------
.wysiwyg-content {
	blockquote {
		margin: 0 0 $global-spacing 0;
		padding: 15px 15px 15px 100px;
		position: relative;

		&::before {
			@include background-image('icon-quote.png', $size: initial);

			content: '';
			display: block;
			height: 34px;
			left: 0;
			position: absolute;
			top: 0;
			width: 50px;
		}

		& > p {
			color: $color-theme-black;
		}

		cite {
			font-style: normal;
			font-weight: $font-weight-bold;
		}
	}
}

//-----------------------------------------
// Cite
//-----------------------------------------
.wysiwyg-content {
	cite {
		color: $color-theme-black;
		display: block;

		&::before {
			content: '-';
			display: inline-block;
			margin-right: 5px;
		}
	}
}

//-----------------------------------------
// Anchor
//-----------------------------------------
.wysiwyg-content {
	a {
		position: relative;

		&:not(.button):not(.wp-block-button__link) {
			color: $color-secondary;
			text-decoration: underline;
			word-break: break-all;

			&:focus,
			&:hover {
				color: $color-secondary-hover;
			}
		}
	}
}

//-----------------------------------------
// Image
//-----------------------------------------
.wysiwyg-content {
	img {
		display: block;
		height: auto;
		line-height: 0;
		width: auto;
	}
}
