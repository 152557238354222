//--------------------------------------------------------------
// COMPONENT CARD
//--------------------------------------------------------------

.c-card {
	background-color: $color-white;
	display: grid;

	&__top {
		margin: 0;
		position: relative;
		z-index: 1;
	}

	&__image {
		display: block;
	}

	&__type {
		font-size: rem-calc(16);
		font-weight: $font-weight-bold;
		line-height: 1;
		padding: 20px;
		position: absolute;
		text-shadow: 0 0 5px rgba($color-black, 0.8);
		text-transform: uppercase;

		&--white {
			color: $color-white;
		}
	}

	&__title {
		background-color: $color-white;
		font-size: rem-calc(22);
		margin: -45px 0 0;
		max-width: 270px;
		padding: 20px 30px;
		position: relative;
		z-index: 2;
	}

	&__section {
		padding: 0 30px 30px;
	}

	&__description {
		font-size: rem-calc(16);
	}

	&__footer {
		align-items: center;
		align-self: end;
		display: flex;
		justify-content: space-between;
		padding: 0 30px 30px;

		@include mq($mq-mobile-medium, false) {
			align-items: flex-start;
			flex-direction: column;
		}
	}

	&__button {
		@include mq($mq-mobile-medium, false) {
			margin-bottom: 20px;
		}

		&.button {
			width: auto;
		}
	}

	&__more-info {
		font-size: rem-calc(15);
		font-weight: bold;
		text-decoration: underline;
	}
}
