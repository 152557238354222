//--------------------------------------------------------------
// BUTTONS
//--------------------------------------------------------------

.button {
	appearance: none;
	background-color: $color-primary;
	border: 0;
	border-radius: $global-radius;
	color: $color-white;
	cursor: pointer;
	display: inline-block;
	font-size: rem-calc(16);
	font-weight: $font-weight-bold;
	line-height: 1;
	margin: 0;
	padding: 15px 22px;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	width: 100%;

	@include mq($mq-tablet) {
		width: auto;
	}

	&:focus,
	&:hover {
		background-color: $color-primary-hover;
		color: $color-white;
	}

	&--secondary {
		background-color: $color-secondary;
		color: $color-white;

		&:focus,
		&:hover {
			background-color: $color-secondary-hover;
			color: $color-white;
		}
	}

	&--full-width {
		display: block;
		margin-left: 0;
		margin-right: 0;

		@include mq($mq-tablet) {
			width: 100%;
		}
	}

	&--hollow {
		background-color: transparent;
		border: 1px solid $color-secondary;
		color: $color-secondary;

		&:focus,
		&:hover {
			background-color: $color-secondary;
			color: $color-white;
		}
	}

	&--round {
		border-radius: 30px;
		padding: 12px 16px;
	}

	&--reset {
		background-color: transparent;
		border: 0;
	}

	&--no-expand {
		width: auto;
	}

	&[disabled] {
		background-color: transparent;
		border: 1px solid $color-theme-gray;
		color: $color-theme-gray;
		cursor: default;
	}
}
