//--------------------------------------------------------------
// PRODUCT CARD
//--------------------------------------------------------------
$mediaChangeMd: 840px;
.product-cards {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  flex: 1;
  max-width: $global-width;
  width: 100%;
  margin: 0 auto;

  @include mq($mediaChangeMd) {
    grid-template-columns: repeat(auto-fit, 370px);
  }
}

.product-card {
  width: 100%;
  background-color: $color-white;
  padding: 10px 20px 20px;
  display: flex;
  flex-direction: column;

  @include mq($mediaChangeMd) {
    width: 370px;
  }

  &__img {
    margin-bottom: 20px;
    width: 100% !important;
    max-height: auto;
    object-fit: contain;

    @include mq($mediaChangeMd) {
      max-height: 240px;
      min-height: 220px;
    }
  }

  &__category {
    color: black;
    letter-spacing: 1px;
    min-height: 18px;
    width: 100%;
  }

  &__title {
    font-size: rem-calc(22);
    margin: 0 0 10px;
  }

  &__description {
    font-size: rem-calc(16);
    margin-bottom: 15px;
    p {
      margin: 0;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
    flex: 1;
  }

  &__details &__button {
    margin-bottom: 10px;
  }

  &__details &__rates {
    margin: 0;
    padding: 0;
  }
  &__rate {
    list-style: none;
  }
}
