//--------------------------------------------------------------
// GLOBAL FOOTER
//--------------------------------------------------------------

.g-footer {
	@include background-image('background-footer.jpg');

	color: $color-theme-white;
	min-height: 480px;
	padding: 50px 0;
	position: relative;
	width: 100%;

	&__container {
		position: relative;
		z-index: 2;
	}

	&__top {
		border-bottom: 1px solid rgba($color-white, 0.2);
		display: grid;
		gap: 30px 50px;
		grid-template-columns: 1fr;
		margin-bottom: 40px;
		padding-bottom: 80px;

		@include mq($mq-tablet) {
			grid-template-columns: repeat(4, 1fr);
		}
		@include mq($mq-laptop) {
			gap: 30px 110px;
		}
	}

	&__bottom {
		align-items: center;
		display: grid;
		gap: 30px 100px;

		@include mq($mq-laptop) {
			grid-template-columns: 1fr 400px;
		}
	}

	&__overlay {
		background-color: rgba(#223744, 0.9);
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

//-----------------------------------------
// Company Info
//-----------------------------------------
.g-footer {
	&__company-info {
		text-align: center;
	}

	&__logo {
		display: block;
		margin-bottom: 20px;
	}
}

//-----------------------------------------
// Menu
//-----------------------------------------
.g-footer {
	&__menu-container {
		padding-top: 20px;

		@include mq($mq-tablet, false) {
			text-align: center;
		}
	}

	&__menu {
		// Override main menu.
		.navigation__item {
			font-size: rem-calc(15);
			margin-bottom: 15px;

			&:not(:first-child) {
				margin-left: 0;
			}
		}
	}

	&__menu-header {
		color: $color-white;
		font-size: rem-calc(18);
		margin-bottom: 28px;
	}
}

//-----------------------------------------
// Actions
//-----------------------------------------
.g-footer {
	&__actions {
		padding-top: 20px;
	}
}

//-----------------------------------------
// Logos
//-----------------------------------------
.g-footer {
	&__logos {
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr 1fr;

		@include mq($mq-tablet) {
			display: flex;
			justify-content: space-between;
		}
	}

	&__logo-image {
		display: block;
	}
}

//-----------------------------------------
// Copyright
//-----------------------------------------
.g-footer {
	&__copyright-container {
		text-align: center;

		@include mq($mq-laptop) {
			text-align: right;
		}
	}

	&__copyright {
		font-size: rem-calc(12);
	}

	&__links {
		display: flex;
		font-size: rem-calc(12);
		justify-content: center;

		@include mq($mq-laptop) {
			justify-content: flex-end;
		}
	}

	&__link {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 10px;

			&::after {
				content: '|';
				display: inline-block;
				padding-left: 10px;
			}
		}
	}
}
