//--------------------------------------------------------------
// BLOCK ACCORDION
//--------------------------------------------------------------

.block-accordion {
	$accordion: &;

	margin-bottom: 75px;

	// <details>
	&__item {
		margin-bottom: 1px;

		&[open] {
			#{$accordion}__title {
				background-color: $color-primary-hover;

				&--gold {
					background-color: $color-gold;
				}

				&--silver {
					background-color: $color-silver;
				}

				&--bronze {
					background-color: $color-bronze;
				}

				&--junior {
					background-color: $color-junior;
				}
			}

			#{$accordion}__icon-open {
				display: block;
			}

			#{$accordion}__icon-close {
				display: none;
			}
		}
	}

	// <summary>
	&__title {
		align-items: center;
		background-color: $color-primary;
		color: $color-white;
		display: flex;
		font-size: rem-calc(20);
		font-weight: $font-weight-bold;
		padding: 20px;

		&::-webkit-details-marker {
			display: none;
		}

		&:focus,
		&:hover {
			background-color: $color-primary-hover;
		}

		&--gold {
			background-color: $color-gold;

			&:focus,
			&:hover {
				background-color: darken($color-gold, 5%);
			}
		}

		&--silver {
			background-color: $color-silver;

			&:focus,
			&:hover {
				background-color: darken($color-silver, 5%);
			}
		}

		&--bronze {
			background-color: $color-bronze;

			&:focus,
			&:hover {
				background-color: darken($color-bronze, 5%);
			}
		}

		&--junior {
			background-color: $color-junior;

			&:focus,
			&:hover {
				background-color: darken($color-junior, 5%);
			}
		}

		&--gold,
		&--silver,
		&--bronze,
		&--junior {
			text-shadow: 0 0 3px rgba($color-black, 0.4);
		}
	}

	// Content
	&__content {
		background-color: $color-theme-gray;
		padding: 20px;
	}

	&__p {
		.wysiwyg-content & {
			margin-bottom: 0;
		}
	}

	// Icons
	&__icons {
		margin-right: 10px;
	}

	&__icon-open {
		display: none;
	}
}

//-----------------------------------------
// Contact
//-----------------------------------------
.block-accordion {
	$accordion: &;

	&--contact {
		#{$accordion}__content {
			padding: 0;
		}

		#{$accordion}__p {
			padding: 20px;
		}
	}
}

//-----------------------------------------
// Packages
//-----------------------------------------
.block-accordion {
	&__content-package {
	}

	&__content-package-details {
	}

	&__content-package-image {
	}

	&__content-package-gallery {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 30px;

		@include mq($mq-tablet) {
			grid-template-columns: repeat(auto-fit, minmax(175px, 175px));
		}
	}

	&__content-package-gallery-item {
		.gallery-caption {
			display: block;
			padding: 5px;
		}
	}


	&__content-package-book-container {
		align-items: start;
		display: grid;
		gap: 30px;

		@include mq($mq-tablet) {
			grid-template-columns: 130px 1fr;
		}
	}

	&__content-package-book-details {
		font-size: rem-calc(12);
	}
}
