//--------------------------------------------------------------
// GLOBAL HEADER
//--------------------------------------------------------------

.g-header {
	background: linear-gradient(180deg, rgba(111, 111, 111, 0.8) 0%, rgba($color-black, 0) 100%);
	position: absolute;
	width: 100%;
	z-index: 2;

	@include mq($mq-laptop) {
		padding: 20px;
	}
	@include mq($mq-global-width) {
		padding: 20px 100px;
	}

	&__container {
		align-items: center;
		display: grid;
		gap: 20px 50px;

		@include mq($mq-laptop) {
			grid-template-columns: 250px 1fr;
		}
	}

	&__logo-container {
		@include mq($mq-laptop, false) {
			text-align: center;
		}
	}

	&__logo {
		display: block;
	}

	&__logo-image {
		@include mq($mq-laptop, false) {
			max-width: 150px;
		}
	}

	&__actions {
		align-items: center;
		display: flex;
		justify-content: flex-end;

		@include mq($mq-laptop, false) {
			padding-left: 20px;
		}
		@include mq($mq-laptop) {
			margin-bottom: 30px;
		}

		&-item {
			color: $color-white;
			line-height: 1;

			&:not(:first-child) {
				margin-left: 35px;
			}

			&--social {
				@include mq($mq-laptop, false) {
					display: none;
				}
			}
		}

		&-phone {
			font-size: rem-calc(20);

			@include mq($mq-laptop, false) {
				color: $color-theme-black;
			}

			&:focus,
			&:hover {
				color: $color-theme-black;
			}
		}

		&-icon {
			font-size: rem-calc(16);

			@include mq($mq-laptop, false) {
				color: $color-secondary;
			}
		}
	}

	&__navigation {
		justify-self: end;

		@include mq($mq-laptop, false) {
			background-color: $color-white;
			display: flex;
			justify-content: space-between;
			order: -1;
			width: 100%;
		}
	}
}
