//--------------------------------------------------------------
// IMAGE TEXT
//--------------------------------------------------------------

.image-text {
	@include background-image('texture-cartographer.png', $repeat: repeat, $size: initial);

	background-color: $color-primary;

	&__container {
		align-items: center;
		display: grid;
		gap: 40px;
		padding-bottom: 40px;

		@include mq($mq-laptop) {
			grid-template-columns: 1fr 1fr;
			padding-bottom: 0;
		}
	}

	&__image {
		max-height: 535px;
		object-fit: cover;
	}

	&__content {
		color: $color-white;
		max-width: 570px;

		@include mq($mq-laptop, false) {
			padding-left: $global-padding;
		}
		@include mq($mq-global-width, false) {
			padding-right: $global-padding;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: inherit;
		}
	}
}
