//--------------------------------------------------------------
// BOOK NOW - STEPS
//--------------------------------------------------------------

.book-now-steps {
	background-color: rgba($color-black, 0.7);

	&__steps {
		display: grid;
		grid-template-columns: 1fr;
		padding: 0;

		@include mq($mq-global-width) {
			grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
			padding: 0 $global-padding;
		}
	}

	&__step {
		color: $color-white;
		padding: 10px;
		text-align: center;

		@include mq($mq-global-width) {
			border-left: 1px solid $color-white;
			padding: 20px 10px;
		}

		&:last-child {
			@include mq($mq-global-width) {
				border-right: 1px solid $color-white;
			}
		}

		&.is-active {
			background-color: $color-secondary;
		}
	}
}
