//--------------------------------------------------------------
// SLIDER BOOK NOW
//--------------------------------------------------------------

.slider-book-now {
	background-color: $color-white;
	max-width: 1180px;
	padding: 30px 40px;

	@include mq($mq-global-width) {
		left: 0;
		position: absolute;
		right: 0;
		transform: translateY(-250px);
	}

	&__heading {
		font-size: rem-calc(42);
		font-weight: $font-weight-bold;
		line-height: 0.9;
		margin-bottom: 10px;
	}

	&__form {
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr;

		@include mq($mq-global-width) {
			grid-template-columns: 330px 170px 170px 230px 1fr;
		}
	}

	&__label {
		font-size: rem-calc(14);
	}

	&__button {
		align-self: end;
	}
}
