//--------------------------------------------------------------
// DELIVERY
//--------------------------------------------------------------

.delivery {
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	min-height: 600px;

	&__content {
		max-width: 360px;
		text-align: center;
	}
}
