//--------------------------------------------------------------
// STATES
//--------------------------------------------------------------

// Prevent site from scrolling.
.no-scroll {
	height: 100%;
	overflow: hidden;
}

label.is-error {
	color: $color-error;
}
