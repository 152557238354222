//--------------------------------------------------------------
// FORM SEARCH
//--------------------------------------------------------------

.form-search {
	display: flex;
	margin: 20px 0;
	width: 260px;

	&__input {
		margin: 0;
		width: 100%;
	}

	&__submit {
	}
}
