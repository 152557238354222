//--------------------------------------------------------------
// COMPONENT BANNER
//--------------------------------------------------------------

.c-banner {
	background-color: $color-primary;
	color: $color-white;
	margin-bottom: $global-spacing;
	padding: 15px 0;
	text-align: center;

	h2 {
		color: inherit;
	}

	&__message {
	}
}
