//--------------------------------------------------------------
// ITEMS CARD
//--------------------------------------------------------------
$mediaChangeMd: 840px;
.items-cards {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  flex: 1;
  max-width: $global-width;
  width: 100%;
  margin: 0 auto;

  @include mq($mediaChangeMd) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.items-card {
  position: relative;
  width: 100%;
  background-color: $color-white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-width: 5px 0 0;
  border-style: solid;
  border-radius: 3px;
  box-shadow: 0 1px 5px 0px rgba(0,0,0,0.15);



  &__category {
    color: white;
    letter-spacing: 1px;
    min-height: 18px;
    width: fit-content;
    margin: -20px 0 10px -20px;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 0 0 3px;
  }
  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
  }

  &__title {
    font-size: rem-calc(22);
    margin: 0 0 10px;
  }

  &__description {
    font-size: rem-calc(16);
    margin-bottom: 15px;
    p {
      margin: 0;
      font-size: 15px;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    margin-bottom: 0 !important;
  }
}
