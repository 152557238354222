//--------------------------------------------------------------
// NAVIGATION
//--------------------------------------------------------------

.navigation {
	position: relative;
	z-index: 99;
}

//-----------------------------------------
// Menu
//-----------------------------------------
.navigation {
	// <ul>
	&__menu {
		display: none;
		margin: 0;
		padding: 0;

		@include mq($mq-laptop) {
			display: flex;
		}
	}

	// <li>
	&__item {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;

		&:not(:first-child) {
			@include mq($mq-laptop) {
				margin-left: 55px;
			}
		}

		// Current page link.
		&.current-menu-item {
			& > a {
				border-bottom: 1px solid $color-secondary;
				color: $color-white;
			}
		}
	}

	// <a>
	a {
		border-bottom: 1px solid transparent;
		color: $color-white;
		display: block;
		font-size: rem-calc(20);
		font-weight: $font-weight-bold;
		padding: 10px;

		@include mq($mq-laptop) {
			padding: 0 0 3px;
		}

		&:focus,
		&:hover {
			border-bottom: 1px solid $color-secondary;
			color: $color-white;

			&::after {
				border-color: $color-theme-white transparent transparent;
			}
		}
	}
}

//-----------------------------------------
// Menu - Sub Menu - Parent
//-----------------------------------------
.navigation {
	$nav: &;

	// <li>
	&__sub-menu-parent {
		& > a {
			&::after {
				border: 4px solid;
				border-color: $color-theme-white transparent transparent;
				content: '';
				display: block;
				position: absolute;
				right: -15px;
				top: 40%;
			}
		}

		&:hover {
			// Keep parent <li> appearing hovered.
			& > a {
				color: $color-theme-white;

				&::after {
					border-color: $color-theme-white transparent transparent;
				}
			}

			#{$nav}__sub-menu {
				display: block;
			}
		}
	}
}

//-----------------------------------------
// Menu - Sub Menu
//-----------------------------------------
.navigation {
	$nav: &;

	&__sub-menu {
		box-shadow: 0 2px 3px 0 rgba($color-theme-black, 0.3);
		display: none;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		z-index: 98;

		a {
			background-color: $color-primary;
			color: $color-theme-white;
			display: block;
			font-size: rem-calc(16);
			padding: 15px 20px;
			white-space: nowrap;

			@include mq($mq-laptop) {
				padding: 10px;
			}

			&:focus,
			&:hover {
				background-color: $color-primary-hover;
				border-bottom: 1px solid transparent;
				color: $color-theme-white;
			}
		}

		#{$nav}__item {
			@include mq($mq-laptop) {
				margin-left: 0;
			}

			&:not(:last-child) {
				border-bottom: 1px solid $color-white;
			}

			// Current page link.
			&.current-menu-item {
				a {
					background-color: $color-primary-hover;
					border-bottom: 1px solid transparent;
				}
			}
		}
	}
}

//-----------------------------------------
// Menu - Description
//-----------------------------------------
.navigation {
	&__description {
		display: block;
		font-size: rem-calc(9);
		padding-left: 5px;
	}
}

//-----------------------------------------
// Menu - Active
//-----------------------------------------
.navigation {
	&__menu.is-active {
		background-color: $color-primary;
		display: block;
		height: 100%;
		left: 0;
		min-width: 230px;
		overflow-y: auto;
		position: fixed;
		top: 0;
		z-index: 99;

		li {
			border-bottom: 1px solid $color-theme-white;

			a {
				color: $color-theme-white;

				&:focus,
				&:hover {
					background-color: darken($color-primary, 20%);
				}

				&::after {
					border-color: $color-theme-white transparent transparent;
				}
			}
		}
	}
}

//-----------------------------------------
// Menu - Active - Sub Menu
//-----------------------------------------
.navigation {
	$nav: &;

	&__menu.is-active {
		#{$nav}__sub-menu {
			display: block;
			position: relative;
		}
	}
}

//-----------------------------------------
// Menu Trigger
//-----------------------------------------
.navigation {
	&__menu-trigger {
		background-color: $color-secondary;
		color: $color-theme-white;
		cursor: pointer;
		display: block;
		font-size: rem-calc(30);
		line-height: 1;
		padding: 10px;

		@include mq($mq-laptop) {
			display: none;
		}

		&:focus,
		&:hover {
			background-color: $color-primary;
		}
	}
}

//-----------------------------------------
// Overlay
//-----------------------------------------
.navigation {
	&__overlay {
		background-color: rgba($color-black, 0.7);
		bottom: 0;
		display: block;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 98;
	}
}
