//--------------------------------------------------------------
// BOOK NOW
//--------------------------------------------------------------

//-----------------------------------------
// All Steps
//-----------------------------------------
.book-now {
	&__step-controls {
		color: $color-primary;
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}

	&__form {
	}

	&__form-section {
		margin-bottom: 60px;
	}

	&__form-group {
		margin-bottom: $global-spacing;
	}

	&__form-columns {
		display: grid;
		gap: $global-spacing;

		@include mq($mq-tablet) {
			grid-template-columns: 1fr 1fr;
		}
	}
}

.referrer-greeting {
	margin-bottom: $global-spacing;

	@include mq($mq-tablet) {
		max-width: 480px;
	}
}

//-----------------------------------------
// Step 1
//-----------------------------------------
.book-now {
	&__step-1-content {
		margin-bottom: 60px;
	}

	&__general {
		position: relative;

		@include mq($mq-laptop) {
			min-height: 900px;
		}
	}

	&__general-background {
		bottom: 0;
		opacity: 0.1;
		position: absolute;
		right: 0;
		z-index: 1;

		@include mq($mq-laptop) {
			bottom: initial;
			top: 0;
		}
		@include mq($mq-global-width) {
			opacity: 1;
		}
	}

	&__general-form {
		position: relative;
		z-index: 2;

		@include mq($mq-tablet) {
			max-width: 480px;
		}
	}

	&__general-form-group {
		margin-bottom: $global-spacing;

		&--columns {
			display: grid;
			gap: $global-spacing;
			grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
		}
	}

	&__general-form-input {
		background-color: transparent;
	}

	// Discount Codes
	.last-minute-booking {
		background-color: $color-mercury;
		border: 1px solid $color-secondary;
		border-radius: $global-radius;
		margin-bottom: $global-spacing;
		padding: 20px;

		&.wysiwyg-content {
			font-size: rem-calc(16);
		}
	}
}

//-----------------------------------------
// Step 2
//-----------------------------------------
.book-now {
	&__step-2 {
	}

	&__step-2-row {
		max-width: $mq-laptop-large;
	}

	&__table {
		input,
		textarea,
		select {
			background-color: transparent;
		}
	}

	&__table-group {
		display: flex;
		justify-content: space-between;
	}

	&__table-input-right-space {
		margin-right: 20px;
	}

	&__add-renter {
		align-items: center;
		color: $color-secondary;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 50px;
	}

	&__add-renter-text {
		display: inline-block;
		margin-right: 10px;
	}

	// <a>
	&__remove-renter {
		color: $color-error;

		&:focus,
		&:hover {
			color: darken($color-error, 10%);
		}
	}

	&__comments {
		display: grid;
		gap: 30px;
		margin-bottom: 40px;

		@include mq($mq-tablet) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__comment {
		min-height: 120px;
	}
}

//-----------------------------------------
// Step 3
//-----------------------------------------
.book-now {
	&__step-3 {
	}

	&__step-3-details {
		margin-bottom: 60px;
		text-align: center;
	}

	&__sub-title {
		font-size: rem-calc(30);
		font-weight: $font-weight-bold;
	}

	&__rental-total {
		color: $color-secondary;
		font-size: rem-calc(60);
		font-weight: $font-weight-bold;
	}
}

//-----------------------------------------
// Complete
//-----------------------------------------
.book-now {
	&__complete {
	}

	&__complete-container {
	}

	&__reservation-code {
		color: $color-secondary;
		font-size: rem-calc(60);
		font-weight: $font-weight-bold;
	}
}

//-----------------------------------------
// Review
//-----------------------------------------
.book-now {
	&__review-content {
		margin-bottom: 60px;
	}

	&__review {
	}

	&__review-columns {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
		margin-bottom: 60px;
	}

	&__review-general {
		&-title {
			background-color: $color-secondary;
			border-radius: $global-radius $global-radius 0 0;
			color: $color-white;
			font-weight: $font-weight-bold;
			padding: 12px 20px;
		}

		&-item {
			border: 1px solid rgba($color-black, 0.125);
			border-top: 0;
			padding: 12px 20px;

			&:last-child {
				border-radius: 0 0 $global-radius $global-radius;
			}
		}
	}

	&__review-savings {
		margin-bottom: 60px;
	}

	&__review-renters {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
		margin-bottom: 60px;

		@include mq($mq-global-width) {
			grid-template-columns: repeat(auto-fit, 370px);
		}
	}

	&__review-renter {
		&-title {
			background-color: $color-primary;
			border-radius: $global-radius $global-radius 0 0;
			color: $color-white;
			font-weight: $font-weight-bold;
			padding: 12px 20px;
		}

		&-item {
			border: 1px solid rgba($color-black, 0.125);
			border-top: 0;
			padding: 12px 20px;

			&:last-child {
				border-radius: 0 0 $global-radius $global-radius;
			}
		}
	}
}
