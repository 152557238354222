//--------------------------------------------------------------
// SLICK CUSTOM
//--------------------------------------------------------------

//-----------------------------------------
// Slick Slider
//-----------------------------------------
.slick-slider {
	position: relative;
	width: 100%;

	&.slick-dotted {
		margin-bottom: 0;
	}

	&__slide {
		display: block !important;
		max-height: 950px;
	}

	&__slide-content {
		bottom: 20px;
		display: grid;
		grid-template: 'content';
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		padding: 0 20px;
		position: absolute;
		text-align: center;
		width: 100%;

		@include mq($mq-global-width) {
			bottom: 40vh;
			grid-template: '. content .';
			grid-template-columns: 1fr $global-width 1fr;
			grid-template-rows: 1fr auto;
		}
	}

	&__heading {
		color: $color-white;
		font-family: $font-family-heading;
		font-size: 25px;
		grid-area: content;
		line-height: 1.1;
		text-shadow: 0 0 9px rgba($color-black, 0.5);
		z-index: 2;

		@include mq($mq-laptop) {
			font-size: 80px;
		}
	}

	&__description {
		color: $color-white;
		font-size: rem-calc(14);
		grid-area: content;
		grid-row: 2;
		text-shadow: 0 0 9px rgba($color-black, 0.5);

		@include mq($mq-tablet) {
			font-size: rem-calc(24);
		}
	}

	&__image {
		@include mq($mq-tablet, false) {
			height: 360px;
			object-fit: cover;
		}
	}
}

//-----------------------------------------
// Slick Theme
//-----------------------------------------
.slick-prev,
.slick-next {
	z-index: 99;
}

.slick-prev {
	left: 20px;
}

.slick-next {
	right: 20px;
}

.slick-dots {
	background-color: rgba($color-theme-black, 0.7);
	bottom: 30px;
	display: none !important;
	flex-direction: column;
	margin: 0 100px 0 0;
	padding: 15px;
	right: 0;
	width: auto;

	@include mq($mq-laptop-large) {
		display: flex !important;
	}

	li {
		height: auto;
		margin: 0;
		width: auto;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&.slick-active {
			button::before {
				background-color: $color-white;
				opacity: 1;
			}
		}

		button {
			height: 12px;
			padding: 0;
			width: 12px;

			&::before {
				border: 1px solid $color-white;
				border-radius: 50%;
				color: $color-white;
				content: '';
				display: block;
				height: 12px;
				opacity: 1;
				position: relative;
				width: 12px;
			}
		}
	}
}

//-----------------------------------------
// Slick
//-----------------------------------------
.slick-slide {
	img {
		// background-size: cover;
		display: block;
		width: 100%;
	}
}
