//--------------------------------------------------------------
// GLOBAL PAGE HEADER
//--------------------------------------------------------------

.g-page-header {
	&__background {
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: $global-spacing;
		min-height: 450px;

		&.lazyloaded {
			display: flex;
		}

		&--is-banner {
			margin-bottom: 0;
		}
	}

	&__title {
		margin-bottom: 50px;
	}
}
