//--------------------------------------------------------------
// CUSTOMER REVIEWS
//--------------------------------------------------------------

.customer-reviews {
	margin-bottom: 80px;

	&__heading {
		margin-bottom: 40px;
	}

	&__message {
	}

	&__author {
	}

	// Slide Buttons
	.slick-prev,
	.slick-next {
		background-color: $color-mercury;
		color: $color-theme-black;
		display: none !important;
		font-size: initial;
		height: auto;
		left: 0;
		padding: 12px 16px;
		right: 0;
		top: 0;
		transform: translate(430px, -85px);

		@include mq($mq-tablet) {
			display: block !important;
		}

		&::before {
			display: none;
		}

		&:focus,
		&:hover {
			background-color: $color-primary;
			color: $color-white;
		}
	}

	.slick-next {
		left: 60px;
	}
}
