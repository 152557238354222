//--------------------------------------------------------------
// LOCATIONS
//--------------------------------------------------------------

.locations {
	display: grid;
	position: relative;

	@include mq($mq-laptop) {
		align-items: center;
		grid-template-columns: 1fr 1fr;
	}

	&__content {
		position: relative;
	}

	&__content-container {
		display: grid;
		grid-template-columns: 1fr;
		padding-top: $global-padding;
		position: absolute;
		width: 100%;
		z-index: 2;

		@include mq($mq-laptop) {
			grid-template-columns: 1fr $global-width 1fr;
			padding-top: 0;
		}

		.g-row {
			grid-column: 2;
			margin: 0;

			@include mq($mq-laptop) {
				max-width: 510px;
			}
		}
	}

	&__image {
		max-height: 700px;
		min-height: 700px;
		object-fit: cover;
	}

	&__image-overlay {
		background-color: rgba($color-black, 0.6);
		bottom: 0;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	&__heading {
		color: $color-white;

		@include mq($mq-tablet) {
			max-width: 330px;
		}
	}

	&__description {
		color: $color-white;
	}
}

//-----------------------------------------
// Names
//-----------------------------------------
.locations {
	&__names {
		color: $color-white;
		margin-top: 30px;
	}

	// <li>
	&__name {
		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	&__name-link {
		text-decoration: underline;
	}
}

//-----------------------------------------
// Google Map
//-----------------------------------------
.locations {
	&__map {
		position: relative;
		z-index: 20;
	}
}

#google-map {
	height: 100%;
	min-height: 700px;
	width: 100%;
}
