//--------------------------------------------------------------
// EQUIPMENT RATES LOCATIONS
//--------------------------------------------------------------

.equipment-rates-locations {
	margin-bottom: 60px;

	@include mq($mq-laptop) {
		display: grid;
		gap: 60px;
		grid-template-columns: 1fr 500px;
	}

	&__packages,
	&__prices {
		@include mq($mq-laptop, false) {
			margin-bottom: 30px;
		}
	}
}
