//--------------------------------------------------------------
// SIDEBAR
//--------------------------------------------------------------

.sidebar-widget {
	.searchform {
		margin-bottom: $global-spacing;

		input[type='text'] {
			margin-bottom: $global-spacing;
		}
	}

	#searchsubmit {
		@extend .button;
	}
}

//-----------------------------------------
// Content Sidebar
//-----------------------------------------
.content-sidebar {
	display: grid;
	gap: 30px;
	margin-bottom: $global-spacing;

	@include mq($mq-laptop) {
		grid-template-columns: 1fr 300px;
	}
}
