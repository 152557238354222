a.summer-contact {
  border-radius: 3px;
  background-color: $color-secondary;
  color: white !important;
  padding: 20px 30px;
  margin: 20px auto;
  text-decoration: none !important;
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: darken($color-secondary, 15);
  }
}