//--------------------------------------------------------------
// BLOCK TWO COLUMN
//--------------------------------------------------------------

.block-two-column {
	display: grid;
	gap: 60px;
	grid-template-columns: 1fr;
	margin-bottom: 75px;

	@include mq($mq-tablet) {
		grid-template-columns: 1fr 1fr;
	}
	@include mq($mq-laptop) {
		grid-template-columns: 750px 1fr;
	}

	&--small-first {
		@include mq($mq-laptop) {
			grid-template-columns: 445px 1fr;
		}
	}

	&__one {
	}

	&__two {
	}
}
