//--------------------------------------------------------------
// GLOBAL ROW
//--------------------------------------------------------------

.g-row {
	display: flow-root;
	margin: 0 auto;
	max-width: $global-width;
	padding: 0 $global-padding;
	position: relative;
	width: 100%;

	// We need this until 'flow-root' is fully supported.
	// https://caniuse.com/#search=flow
	&--clearfix {
		@include clearfix;
	}
}
