//--------------------------------------------------------------
// HELPERS
//--------------------------------------------------------------

.h-visual-hide {
	@include visual-hide;
}

.h-reverse-visual-hide {
	@include reverse-visual-hide;
}

.h-hidden {
	display: none;
}

.h-clearfix {
	@include clearfix;
}

//-----------------------------------------
// Cover
//-----------------------------------------
.h-cover-absolute {
	bottom: 0;
	height: 100%;
	left: 0;
	min-height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.h-cover-background {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.h-cover-video {
	height: auto;
	max-height: none;
	max-width: none;
	min-height: 100%;
	min-width: 100%;
	width: auto;
}

.h-table-responsive {
	overflow-x: auto;

	table {
		min-width: 725px;
	}

	&--rates {
		@include mq($mq-tablet) {
			overflow: initial;
		}

		table {
			@include mq($mq-tablet) {
				min-width: auto;
			}
		}
	}
}

//-----------------------------------------
// Text
//-----------------------------------------
.h-text-center {
	text-align: center;
}

.h-text-bold {
	font-weight: $font-weight-bold;
}

//-----------------------------------------
// Colors
//-----------------------------------------
.h-color-primary {
  color: $color-primary !important;
}

.h-color-secondary {
  color: $color-secondary !important;
}

.h-color-tertiary {
  color: $color-tertiary !important;
}

.h-background-primary {
  background-color: $color-primary !important;
}

.h-background-secondary {
  background-color: $color-secondary !important;
}

.h-background-tertiary {
  background-color: $color-tertiary !important;
}

.h-border-primary {
  border-color: $color-primary !important;
}

.h-border-secondary {
  border-color: $color-secondary !important;
}

.h-border-tertiary {
  border-color: $color-tertiary !important;
}

//-----------------------------------------
// Spacing
//-----------------------------------------
.h-spacing-bottom-none {
  margin-bottom: 0;

  .wysiwyg-content & {
    margin-bottom: 0;
  }
}

.h-spacing-bottom {
  margin-bottom: $global-spacing;
}

.h-spacing-bottom-2 {
  margin-bottom: 60px;
}

// Center Inner content from outter full-width usage
.h-center-inner-full-max-global {
  max-width: $global-width;
  margin: 0 auto;
}
