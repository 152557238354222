//--------------------------------------------------------------
// BACKGROUND IMAGE
//--------------------------------------------------------------

@mixin background-image($image, $position: center, $repeat: no-repeat, $size: cover) {
	background-image: url('../images/#{$image}');
	background-position: $position;
	background-repeat: $repeat;
	background-size: $size;
}
