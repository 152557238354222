//--------------------------------------------------------------
// COMPONENT SOCIAL MEDIA
//--------------------------------------------------------------

.c-social-media {
	display: flex;

	// <li>
	&__item {
		&:not(:last-child) {
			margin-right: 20px;
		}
	}

	// <a>
	&__link {
		color: $color-white;
		font-size: rem-calc(20);

		&:focus,
		&:hover {
			color: $color-theme-black;
		}

		// &[data-color='facebook'] {
		// 	background-color: $color-facebook;
		// }

		// &[data-color='twitter'] {
		// 	background-color: $color-twitter;
		// }

		// &[data-color='youtube'] {
		// 	background-color: $color-youtube;
		// }

		// &[data-color='instagram'] {
		// 	background-color: $color-instagram;
		// }
	}

	svg {
		display: block;
	}
}
