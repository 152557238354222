//--------------------------------------------------------------
// WORDPRESS
//--------------------------------------------------------------

//-----------------------------------------
// Admin Bar
//-----------------------------------------
.admin-bar {
}

//-----------------------------------------
// Other Required Classes
//-----------------------------------------
.sticky {
}

.screen-reader-text {
	@include visual-hide;
}

.gallery-caption {
}

.bypostauthor {
}

//-----------------------------------------
// Page Numbers
//-----------------------------------------
// Specificity needed because class name is shared below.
ul.page-numbers {
	display: flex;
	margin: 0;

	li {
		&:not(:last-child) {
			margin-right: 10px;
		}

		// <a>, <span>
		.page-numbers {
			background-color: $color-primary;
			color: $color-white;
			display: block;
			padding: 10px;

			&:focus,
			&:hover {
				background-color: $color-secondary;
			}
		}

		// <span>
		.current {
			background-color: $color-secondary;
			color: $color-white;
			padding: 10px;
		}
	}
}
