//--------------------------------------------------------------
// BLOCK STATS
//--------------------------------------------------------------

.block-stats {
	@include full-width;

	margin-bottom: 75px;
	padding: 100px 0;
	text-align: center;

	&.lazyloaded {
		width: 100vw;
	}

	&__overlay {
		background-color: rgba($color-black, 0.7);
		bottom: 0;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}

	&__content {
		margin-bottom: 50px;
		max-width: 750px;
		position: relative;
		z-index: 2;
	}

	&__stats {
		position: relative;
		z-index: 2;
	}

	&__title {
		color: $color-white;

		@include mq($mq-tablet, false) {
			font-size: rem-calc(30);
		}
	}

	&__description {
		color: $color-white;
	}
}

//-----------------------------------------
// Stats
//-----------------------------------------
.block-stats {
	// <ul>
	&__stats {
		color: $color-white;
		display: grid;
		gap: 30px;
		grid-template-columns: 1fr;

		@include mq($mq-mobile-large) {
			grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
		}

		.wysiwyg-content & {
			list-style-type: none;
			margin: 0 auto;
		}
	}

	// <li>
	&__stat {
	}

	&__number {
		display: block;
		font-size: rem-calc(30);
		font-weight: $font-weight-bold;
		line-height: 1.2;
		margin-bottom: 10px;
	}

	&__stat-description {
	}
}

//-----------------------------------------
// Home
//-----------------------------------------
.block-stats {
	&--home {
		margin-bottom: 0;
	}

	&__overlay--home {
		background-color: rgba($color-primary, 0.9);
	}

	&__stat-title--home {
		color: $color-white;
		font-size: rem-calc(26);
	}

	&__stat-description--home {
		font-size: rem-calc(16);
	}

	&__icon {
		font-size: rem-calc(50);
		margin-bottom: 30px;
	}
}
