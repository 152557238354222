//--------------------------------------------------------------
// CONTACT
//--------------------------------------------------------------

.contact {
	display: grid;
	gap: 70px;
	grid-template-columns: 1fr;

	@include mq($mq-laptop) {
		grid-template-columns: 1fr 470px;
	}

	&__locations {
	}

	&__map {
		height: 100%;
		min-height: 240px;
		width: 100%;
	}
}
