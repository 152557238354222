//--------------------------------------------------------------
// POSTS
//--------------------------------------------------------------

//-----------------------------------------
// Title
//-----------------------------------------
// <h2>
.post-title {
}

//-----------------------------------------
// Thumbnail
//-----------------------------------------
.post-thumbnail {
}

//-----------------------------------------
// Date
//-----------------------------------------
.post-date {
}

//-----------------------------------------
// Categories
//-----------------------------------------
.post-categories {
}

//-----------------------------------------
// Excerpt
//-----------------------------------------
.post-excerpt {
}

//-----------------------------------------
// Tags
//-----------------------------------------
.post-tags {
	margin-bottom: $global-spacing;

	span {
		display: inline-block;
		margin: 0 5px 5px 0;

		a {
			background-color: $color-primary;
			border-radius: $global-radius;
			color: $color-white;
			display: block;
			font-size: rem-calc(14);
			padding: 5px 8px;

			&:focus,
			&:hover {
				background-color: $color-primary-hover;
				color: $color-white;
			}
		}
	}
}
